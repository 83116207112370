import { Component, Input, OnInit } from '@angular/core';
import { Carrousel } from 'src/app/models/carousel';
import { InAppBrowserService } from 'src/app/services/inAppBrowser/in-app-browser.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {

  @Input() product: Carrousel;
  img: boolean = true;
  basketBtn: boolean = environment.basket;

  constructor(private iab: InAppBrowserService) { }

  ngOnInit() {
  }

  open(url: string){
    this.iab.openWithInAppBrowser(url)
  }

  onLoad() {
    this.product.loader = false;
    this.img = false;
  }
  onError() {
    this.img = true;
    this.product.loader = true;
    this.product.image = ''
  }
}
