import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Menu } from './models/menu';
import { Sociaux } from './models/reseaux';
import { InAppBrowserService } from './services/inAppBrowser/in-app-browser.service';
import { OnesignalService } from './services/onesignal/onesignal.service';
import { PrepareService } from './services/rest/prepare.service';
import { TranslateConfigService } from './services/translate-config.service';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Preferences as Storage } from '@capacitor/preferences';
import { Network } from '@capacitor/network';
import { SettingService } from './services/setting.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { StorageService } from './services/storage/storage.service';
import { MenuController, Platform } from '@ionic/angular';
import { UserProfil } from './models/userprofil';
import { LoaderService } from './services/load/load-data.service';
import { environment } from 'src/environments/environment';
import { GeolocationService } from './services/geolocation.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  MenuData: Array<Menu>;
  Reseaux: Array<Sociaux>;
  userInfo: UserProfil; 
  socialNetwork: boolean;
  is_ready: boolean = false;
  timer: BehaviorSubject<number> = new BehaviorSubject(0);
  interval;
  private promises = [];

  constructor(
    private platform: Platform,
    private menu : MenuController,
    private _translation: TranslateConfigService,
    private _setting: SettingService,
    private api: PrepareService,
    private router: Router,
    private browser: InAppBrowserService,
    private onesignale: OnesignalService,
    private storagesrv: StorageService,
    private spin : LoaderService,
    private geo : GeolocationService
  ) {
    
  }

  ngOnInit(): void {  
    this.initializeApp();
    let timer = 0;
    this.interval = setInterval(() => {
      timer += 0.1;
      this.timer.next(timer);
    },100)

  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.initNetwork();      
      this.promises.push(this._translation.hydrate());
      this.promises.push(this._setting.all());
      this.promises.push(this.Menu());
      this.promises.push(this.Sociaux());
      this.promises.push(this.Storage());
      this.promises.push(this.StorData());
      this.promises.push(this.onesignale.init());
      this.creatCacheFolder();
      Promise.all(this.promises).then( async (_) => {
        this.socialNetwork = await this.storagesrv.getSetting('SOCIAL_NETWORK');
        this.timer.subscribe((t) => {
          if(t >= 3){
            SplashScreen.hide();
            this.is_ready = true;
            clearInterval(this.interval)
          }
        })
        
      });
    })
  }

  async Menu() {
    const menuData = await this.api.getMenu();
    this.MenuData = menuData.map((of) => {
      return new Menu(of);
    })

  }

  async Go(lien) {
    if (lien) {
      this.router.navigate([lien])
    }
  }

  connexion() {
    this.router.navigate(['tabs/compte/login']);

  }

  private async Sociaux() {
    const reseaux = await this.api.getReseaux();
    this.Reseaux = reseaux.map((of) => {
      return new Sociaux(of);
    })

  }

  Open(url) {
    this.browser.openWithInAppBrowser(url);
  }

  private Storage() {
    Storage.get({ key: 'first_time' }).then(async (value) => {
      if (!value.value) {        
        Storage.set({ key: 'first_time', value: 'true' });
      }
      let position = {}
      this.geo.getCurrentPosition().then((res) => {
        position = res;
      }).catch(() => {
        console.log('catch')
      }).finally(() => {

        this.onesignale.id_player.subscribe((val) => {
          if(!val)
            return;
          const data = {
            id_player: val,
            is_first: value.value ? 1 : 0
          }
          position = {
            ...position,
            ...data
          }
          this.api.device(position);
        })
      })
      
    })    
  }

  private StorData() {
    this.api.getUser().then(async (res) => {
      if(res){
        this.storagesrv.setUser(res)
        this.userInfo = await (this.storagesrv.getUser());
        this.onesignale.setTag(res.id_customer);
      }else{
        this.userInfo = null
      }
      this.storagesrv.user.subscribe((res) => {
        if(res){
          this.userInfo = res;
          this.onesignale.setTag(res.id_customer);
        }else{
          this.userInfo = null
        }
      })
    })
    
  }

  compte() {
    this.menu.close()
    this.router.navigate(['tabs/compte'])
  }

  initNetwork(){
    Network.getStatus().then(isConnected => {
      if(!isConnected.connected)
        this.spin.show("WAITING_FOR_INTERNET");
    })
    Network.addListener('networkStatusChange', async(status) => {
      if(!status.connected){
        this.spin.show("WAITING_FOR_INTERNET");
      }else{
        await this.spin.hide();
        try {
          this.ngOnInit()
        } catch (error) {
          
        }
        
      }
    });
  }

  private async creatCacheFolder(){
    try {
      await Filesystem.mkdir({
        directory: Directory.Cache,
        path: environment.path.cache
      })
    } catch (error) {
    }
  }
  
  onError() {
    this.is_ready = false;
  }

  onSuccess() {
    setTimeout(() => {
      SplashScreen.hide();
    },500);   
    this.is_ready = true
  }
}
