export const breakpointsEvent = {
  '@0.00': {
    slidesPerView: 1,
    spaceBetween: 0
  },
  '@0.75': {
    slidesPerView: 2,
    spaceBetween: 0
  },
  '@1.00': {
    slidesPerView: 3,
    spaceBetween: 0
  },
  '@1.50': {
    slidesPerView: 4,
    spaceBetween: 0
  }
}

export const breakpointsCarrousel = {
  '@0.00': {
    slidesPerView: 2,
    spaceBetween: 5
  },
  '@0.75': {
    slidesPerView: 3,
    spaceBetween: 10
  },
  '@1.00': {
    slidesPerView: 4,
    spaceBetween: 20
  },
  '@1.50': {
    slidesPerView: 4,
    spaceBetween: 40
  }
}