import { environment } from 'src/environments/environment';

export class Carrousel {
  id: number;
  name: string;
  price: string;
  image: string;
  size: Size;
  link: string;
  colors ?: Array<string> = [];
  solde: string;
  id_attribute: number;
  loader: boolean = false;
  img_couleur ?: Array<string> = [];
  reference: string;
  card: string;

  constructor(ban) {
    const http = environment.production ? 'https://' : 'http://';
    const tva = environment.tva;
    this.image = ban.image
    this.name = ban.name;
    this.link = ban.link;
    const attributes =  Object.values(ban.attributes) as Array<string>;
    for(let att of attributes){
      const test = this.is_color(att)
      if(test){
        this.colors.push(att)
      }else
        this.img_couleur.push(environment.site_url+att)
    }
    this.loader = true;
    this.reference = ban.reference;
    this.card = ban.link;
    this.price = `${Number(ban.price_without_reduction).toFixed(2)}`
    if (ban.reduction > 0) {
      this.solde = `${Number(ban.price).toFixed(2)}`;
    }
  }

  private is_color(color: string){
    return /^#[0-9A-F]{6}$/i.test(color)
  }
}

export interface Size {
  xs: string;
  md: string;
  lg: string;
}
