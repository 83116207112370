import { Injectable } from '@angular/core';
//import { OneSignal, OSNotification, OSNotificationOpenedResult } from '@ionic-native/onesignal/ngx';
import OneSignal from 'onesignal-cordova-plugin';
import { environment } from 'src/environments/environment';
import { Device, OperatingSystem } from '@capacitor/device';
import { BehaviorSubject } from 'rxjs';
import { NavController } from '@ionic/angular';
import { TranslateConfigService } from '../translate-config.service';



@Injectable({
  providedIn: 'root'
})
export class OnesignalService {

  private _OS: OperatingSystem;
  id_player: BehaviorSubject<string> = new BehaviorSubject(null); 

  constructor(
    private nav: NavController,
    private _translation: TranslateConfigService
  ) { }

  init() {
    OneSignal.setAppId(environment.onesignal.app_id)

    //this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);

    /*this.oneSignal.handleNotificationReceived().subscribe((res: OSNotification) => {
      // do something when notification is received
      //let data : OSNotificationPayload = res.payload;
      this._translation.hydrate();
    });*/

    OneSignal.setNotificationOpenedHandler((res: any) => {
      // do something when a notification is opened
      this._translation.hydrate();
      this.open(res.notification.payload.additionalData);
    });

    this.getPlayerId();
  }

  async getDevice(): Promise<OperatingSystem> {
    const info = await Device.getInfo();
    this._OS = info.operatingSystem;
    console.log(this._OS)
    return this._OS;
  }

  async getDefaultLang() {
    return await Device.getLanguageCode();
  }

  getPlayerId(): Promise<any> {
    return new Promise(async (resolve) => {
      let id_player = "a11cbdd8-19a4-4643-b106-5fde7e34be16";      
      await this.getDevice();
      if (this._OS == 'ios') {
        OneSignal.promptForPushNotificationsWithUserResponse(async (res) => {
          if (!res)
            this.setSubscription(true);
          id_player = await this._playerid()
          this.id_player.next(id_player)
          resolve(id_player);
        })
      } else if(this._OS == 'android'){
        id_player = await this._playerid()
        this.id_player.next(id_player)
        resolve(id_player);
      } else{
        this.id_player.next(id_player)
        resolve(id_player);
      }
    })
  }

  setSubscription(state: boolean) {
    //this.oneSignal.setSubscription(state)
  }

  setTag($id_customer){
    OneSignal.sendTag('id_customer', $id_customer)
  }

  open(link: string) {
    OneSignal.clearOneSignalNotifications();
    this.is_valid_url(link)
    //this.router.navigateByUrl(link);
    this.nav.navigateRoot('tabs/notification')
  }

  private _playerid(): Promise<string> {
    return new Promise(resolve => {
      OneSignal.getDeviceState((player) => {
        resolve(player['userId']);
      })
      
    })
    
  }

  private is_valid_url(link: string) {
    return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(link);
  }
}
