import { Injectable } from '@angular/core';
import { Browser, OpenOptions } from '@capacitor/browser';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class InAppBrowserService {

  constructor(
    private storage : StorageService
  ) { }

  async openWithInAppBrowser(url: any, is_keyNeeded: boolean = true) {
    let key: string;
    if(is_keyNeeded){
      try {
        key = (await this.storage.getUser()).key;
      } catch (error) {
        
      }
    }
    
    const options: OpenOptions = {
      toolbarColor: environment.color,
      url: key ? `${url}?secure_key=${key}` : url,
    };
    return await Browser.open(options);
  }
}
