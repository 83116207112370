import { Component, Input, OnInit } from '@angular/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {

  _image: string;
  @Input() _default: boolean = false;
  _onLoad:boolean = true;

  constructor() { }

  ngOnInit() {}

  @Input()
  set image(imageUrl: string){  
    this.readFile(imageUrl) 
  }

  onLoad() {
    this._onLoad = false;
  }
  onError() {
    this._onLoad = true;
  }

  private readFile(imageUrl){
    const imagename = imageUrl.split('/').pop();
    const filetype = imagename.split('.').pop();
    Filesystem.readFile({
      directory: Directory.Cache,
      path: `${environment.path.cache}/${imagename}`
    }).then(readFile => {
      this._image = `data:image/${filetype};base64,${readFile.data}`
    }).catch(async e => {
      this.storeImage(imageUrl, imagename).then(_ => {
        this.readFile(imageUrl);
      }).catch(async () => {
        this._image = imageUrl
        const fileEntries = await Filesystem.readdir(
          {
            directory: Directory.Cache,
            path: `${environment.path.cache}`
          }
        )
        return fileEntries.files.map(async f => {
          await Filesystem.deleteFile(
            {
              directory: Directory.Cache,
              path: `${environment.path.cache}/${f.name}`
            }
          )
        })
      })
      
    })
  }

  storeImage(url: string, path: string){
    return new Promise(async (resolve, reject) => {
      const response = await fetch(`${url}`);
      const blob = await response.blob();
      const base64Data = await this.convertBlobToBase64(blob) as string;

      Filesystem.writeFile({
        path: `${environment.path.cache}/${path}`,
        data: base64Data,
        directory: Directory.Cache
      }).then(savedFile => {
        resolve(savedFile)
      }).catch(_ => {
        reject()
      })
    })
    
  }

  async convertBlobToBase64(blob: Blob){
    return new Promise((resolve, reject) => {
      const reader = new FileReader;
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.readAsDataURL(blob);
    })
  }
}