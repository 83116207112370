import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-vide',
  templateUrl: './data-vide.component.html',
  styleUrls: ['./data-vide.component.scss'],
})
export class DataVideComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
